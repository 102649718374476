/* You can add global styles to this file, and also import other style files */

/* Import base styles */
@import "~ng-zorro-antd/style/index.min.css";
@import "~ng-zorro-antd/button/style/index.min.css";

// -------- override less variables -----------
$primary-color: #013369;
$secondary-color: #d50a0a;
$tertiary-color: #ffffff;

/* Fonts */
@font-face {
  font-family: "All-Pro Sans Regular";
  src: url("./assets/fonts/All-ProSans-Regular.otf");
}

/* Fonts */
@font-face {
  font-family: "All ProSans Medium";
  src: url("./assets/fonts/All-ProSans-Medium.otf");
}

.nfl-font {
  font-family: "All-Pro Sans Regular";
}

body {
  height: 100vh;
  width: 100vw;
  * {
    font-family: "All-Pro Sans Regular";
  }

  h2,
  h2.ant-typography {
    font-family: "All ProSans Medium";
    color: $primary-color;
    text-transform: capitalize;
  }

  h2:hover {
    cursor: default;
  }
}

.nfl-row {
  margin-top: 2rem;
}

.nfl-form-label.ant-form-item-label label {
  font-family: "All ProSans Medium";
  color: #757575;
  background-color: transparent;
}
